<template>
  <v-container id="transaction-list" tag="section" fluid grid-list-md>
    <base-material-card
      icon="mdi-account-cash"
      :title="$t('transactions.fees list')"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        mobile-breakpoint="500"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        multi-sort
        sort-by="month"
        sort-desc
        :footer-props="{
          'items-per-page-options': [10, 20, 50],
        }"
      >
        <template v-slot:header.debit="{ header }">
          <h2>{{ totals.debit }}</h2>
          {{ header.text }}
        </template>
        <template v-slot:header.win="{ header }">
          <h2>{{ totals.win }}</h2>
          {{ header.text }}
        </template>
        <template v-slot:item.type="{ item }">
          {{ $t("bookings.types." + item.type) }}
        </template>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-select
              v-if="!$route.params.user_id"
              v-model="user_id"
              class="search-field"
              item-value="id"
              :label="$t('transactions.user')"
              hide-details
              :items="forSelect(meta.users)"
              @change="changeOutput"
            ></v-select>
            <v-select
              v-model="month"
              class="search-field"
              item-value="id"
              :label="$t('transactions.month')"
              hide-details
              :items="meta.months"
              @change="changeOutput"
            ></v-select>
            <v-select
              v-model="currency"
              class="search-field"
              item-value="id"
              :label="$t('transactions.currency')"
              hide-details
              :items="currencies"
              @change="changeOutput"
            ></v-select>
          </v-toolbar>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            tag="a"
            class="pr-3"
            download
            :title="$t('fees.download pdf')"
            @click="onDownload(item)"
            >mdi-download</v-icon
          >
          <v-icon
            tag="a"
            class="pr-3"
            download
            :title="$t('fees.download csv')"
            @click="onDownloadCsv(item)"
            >mdi-file-excel</v-icon
          >
          <v-icon
            tag="a"
            class="pr-3"
            download
            :title="$t('fees.download pdf 14')"
            @click="onDownload14(item)"
            >mdi-download</v-icon
          >
        </template>
        <template v-slot:header.actions="{ header }">
          {{ header.text }}
          <v-icon
            v-if="month"
            :title="$t('fees.download totals')"
            @click="onDownloadTotal(month)"
            >mdi-download</v-icon
          >
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import moment from "moment";
import crudMixin from "mixins/crudTable";

export default {
  mixins: [crudMixin],
  data() {
    return {
      search: "",
      apiQuery: "/bookings/fees/",
      headers: [
        { text: this.$t("transactions.user"), align: "start", value: "user" },
        { text: this.$t("transactions.month"), value: "month" },
        {
          text: this.$t("transactions.debit"),
          value: "debit",
          align: "right",
        },
        {
          text: this.$t("transactions.win"),
          value: "win",
          align: "right",
          sortable: false,
        },
        { text: this.$t("transactions.currency"), value: "currency" },
        { text: this.$t("dd.actions"), value: "actions", sortable: false },
      ],
      loading: true,
      currency: "EUR",
      currencies: [
        {
          id: "EUR",
          text: "Euro",
        },
      ],
      user_id: null,
      users: [],
      month: null,
    };
  },
  mounted() {
    this.user_id = this.options.user_id = this.$route.params.user_id
      ? this.$route.params.user_id
      : null;
    this.get(
      "/bookings/meta?" +
        (this.options.user_id ? "user_id=" + this.options.user_id : "")
    ).then((data) => {
      this.meta = data;
    });
    if (this.$route.query.status) {
      this.status = this.$route.query.status.toUpperCase();
      this.$set(this.options, "status", this.status);
    }
    this.$set(this.options, "currency", this.currency);
  },
  methods: {
    changeOutput() {
      this.$set(this.options, "currency", this.currency);
      this.$set(this.options, "user_id", this.user_id);
      this.$set(this.options, "month", this.month);
      this.options.page = 1;
    },
    onDownload(item) {
      this.download(
        this.apiQuery + "download/" + item.user_id + "/" + item.month,
        item.user + "-" + item.month + ".pdf"
      )
        .then(() => {})
        .catch((error) => {
          alert(error.message);
        });
    },
    onDownload14(item) {
      this.download(
        this.apiQuery + "download14/" + item.user_id + "/" + item.month,
        item.user + "-" + item.month + ".pdf"
      )
        .then(() => {})
        .catch((error) => {
          alert(error.message);
        });
    },
    onDownloadCsv(item) {
      this.download(
        this.apiQuery + "download_csv/" + item.user_id + "/" + item.month,
        item.user + "-" + item.month + ".csv"
      )
        .then(() => {})
        .catch((error) => {
          alert(error.message);
        });
    },
    onDownloadTotal(month) {
      this.download(
        this.apiQuery + "download_total/" + month,
        "Total Fees " + month + ".pdf"
      )
        .then(() => {})
        .catch((error) => {
          alert(error.message);
        });
    },
    moment,
  },
};
</script>
<style scoped>
.search-field {
  margin-right: 10px;
}
.break-style {
  word-break: break-word;
  max-width: 7.5rem;
}
</style>
