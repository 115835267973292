var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"transaction-list","tag":"section","fluid":"","grid-list-md":""}},[_c('base-material-card',{attrs:{"icon":"mdi-account-cash","title":_vm.$t('transactions.fees list')}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"mobile-breakpoint":"500","options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"multi-sort":"","sort-by":"month","sort-desc":"","footer-props":{
        'items-per-page-options': [10, 20, 50],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.debit",fn:function(ref){
      var header = ref.header;
return [_c('h2',[_vm._v(_vm._s(_vm.totals.debit))]),_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"header.win",fn:function(ref){
      var header = ref.header;
return [_c('h2',[_vm._v(_vm._s(_vm.totals.win))]),_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"item.type",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t("bookings.types." + item.type))+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[(!_vm.$route.params.user_id)?_c('v-select',{staticClass:"search-field",attrs:{"item-value":"id","label":_vm.$t('transactions.user'),"hide-details":"","items":_vm.forSelect(_vm.meta.users)},on:{"change":_vm.changeOutput},model:{value:(_vm.user_id),callback:function ($$v) {_vm.user_id=$$v},expression:"user_id"}}):_vm._e(),_c('v-select',{staticClass:"search-field",attrs:{"item-value":"id","label":_vm.$t('transactions.month'),"hide-details":"","items":_vm.meta.months},on:{"change":_vm.changeOutput},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}}),_c('v-select',{staticClass:"search-field",attrs:{"item-value":"id","label":_vm.$t('transactions.currency'),"hide-details":"","items":_vm.currencies},on:{"change":_vm.changeOutput},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"pr-3",attrs:{"tag":"a","download":"","title":_vm.$t('fees.download pdf')},on:{"click":function($event){return _vm.onDownload(item)}}},[_vm._v("mdi-download")]),_c('v-icon',{staticClass:"pr-3",attrs:{"tag":"a","download":"","title":_vm.$t('fees.download csv')},on:{"click":function($event){return _vm.onDownloadCsv(item)}}},[_vm._v("mdi-file-excel")]),_c('v-icon',{staticClass:"pr-3",attrs:{"tag":"a","download":"","title":_vm.$t('fees.download pdf 14')},on:{"click":function($event){return _vm.onDownload14(item)}}},[_vm._v("mdi-download")])]}},{key:"header.actions",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),(_vm.month)?_c('v-icon',{attrs:{"title":_vm.$t('fees.download totals')},on:{"click":function($event){return _vm.onDownloadTotal(_vm.month)}}},[_vm._v("mdi-download")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }